import { inject, Inject, Injectable, Optional } from '@angular/core';
import {
  GRPC_SESSION_SERVICE_CLIENT_SETTINGS,
  OpenSessionRequest,
  SessionMessage,
  SessionServiceClient,
  TaskAutoClose,
  TaskInformation,
} from '@camino-solutions/share/proto/common/session';
import { GRPC_CLIENT_FACTORY, GrpcHandler } from '@ngx-grpc/core';
import { GrpcClientFactory, GrpcMetadata } from '@ngx-grpc/common';
import {
  combineLatest,
  filter,
  Observable,
  retry,
  Subject,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { AuthService } from '@camino-solutions/core/auth/service';
import { Actions, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import {
  SessionAddMessageAction,
  SessionChangeSessionMessageAction,
  SessionConnectedAction,
  SessionReConnectingAction,
  SessionRemoveTaskAction,
  SessionState,
} from '@camino-solutions/core/session/state';
import { isMockServerStream } from '@camino-solutions/core/environment';
import { isNil } from '@camino-solutions/utils/typeguard';
import { TaskScheduler } from './task-scheduler/task.scheduler';
import { SchedulerTaskDefinition } from './task-scheduler/scheduler-task-definition';

type TaskId = TaskInformation['taskId'];
let inited = false;

/**
 * Ez a szolgaltatas a proto-ban definialt `SessionService`-t implementalja
 * es kiterjeszti a session -vel kapcsolatos egyeb muveletekre is.
 * Ez az osztaly felel egy delay-vel ellatott bezarando task idozitesere is
 */
@Injectable()
export class SessionService extends SessionServiceClient {
  readonly #authService = inject(AuthService);
  readonly #store = inject(Store);
  readonly #actions = inject(Actions);

  readonly #taskScheduler = new TaskScheduler<TaskAutoClose>();
  #waitingPanelOpen: Map<TaskId, TaskAutoClose> | undefined;

  constructor(
    @Optional() @Inject(GRPC_SESSION_SERVICE_CLIENT_SETTINGS) settings: unknown,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<unknown>,
    handler: GrpcHandler,
  ) {
    super(settings, clientFactory, handler);

    this.#init();
  }

  /**
   * Egy futo task lekerdezese
   * @param taskId
   */
  getScheduledTask(taskId: TaskId): SchedulerTaskDefinition<TaskAutoClose> | undefined {
    return this.#taskScheduler.getActive(taskId);
  }

  /**
   * Server stream session -t nyitja meg
   * Mockolasi lehetosegek miatt kellett felul irni
   * @param requestData
   * @param requestMetadata
   */
  override openSession(
    requestData: OpenSessionRequest,
    requestMetadata: GrpcMetadata = new GrpcMetadata(),
  ): Observable<SessionMessage> {
    if (isMockServerStream()) {
      timer(2000).subscribe(() => this.#sessionConnectedAction());
      return new Subject();
    }
    return super.openSession(requestData, requestMetadata);
  }

  /**
   * A szolgaltatas alapveto funkcio-it inditja el.
   * Ezt a metodust egyszer lehet csak hivni es csak a constructor-bol!
   *
   * A metodus figyeli hogy a user belepett-e es ha igen akkor elinditja a server stream-et
   * Tovabba elindit egy masik figyelot ami az autoClose esemenyek kezeleseert felel.
   *
   * @private
   */
  #init() {
    if (inited === true) {
      throw new Error('[SessionService::init] Duplicate init');
    }
    inited = true;

    /**
     * Ha a user be van lepve es az auth folyamat is befejezodott,
     * akkor inditjuk a server stream figyelest
     */
    combineLatest([this.#authService.isAuthenticated$, this.#authService.isDoneLoading$])
      .pipe(
        filter(([isAuthenticated, isDoneLoading]) => isAuthenticated && isDoneLoading),
        take(1),
      )
      .subscribe(() => this.#startServerStream());

    /**
     * Az elso sikeres server stream kapcsolodasnal elinditjuk az auto close kezelot
     */
    this.#actions
      .pipe(ofActionDispatched(SessionConnectedAction), take(1))
      .subscribe(() => this.#startAutoCloseRegister());
  }

  /**
   * Server stream feliratkozast inditja es kezeli rxjs retry operatorral
   * @private
   */
  #startServerStream() {
    const emergencyStop$ = new Subject<void>();
    this.openSession(new OpenSessionRequest({}))
      .pipe(
        takeUntil(emergencyStop$),
        retry({
          count: 100,
          resetOnSuccess: true,
          delay: (error, retryCount) =>
            timer(1000 * retryCount).pipe(
              tap(() => this.#store.dispatch(new SessionReConnectingAction())),
            ),
        }),
        tap(() => this.#sessionConnectedAction()),
      )
      .subscribe(message => {
        console.log('new session message:', message.toProtobufJSON());
        if (!isNil(message.control?.closed)) {
          console.info('EMERGENCY STOP SESSION');
          emergencyStop$.next();
          emergencyStop$.complete();
          this.#authService.logout();
        }
        // A stream -en kapott uzenetett eldobjuk hogy mindenki fel tudja dolgozni akinek van vele dolga
        this.#store.dispatch(new SessionAddMessageAction(message));
      });
  }

  /**
   * Jelzi az allapotkozpontnak hogy a server stream kapcsolodott
   * @private
   */
  #sessionConnectedAction() {
    return this.#store.dispatch(new SessionConnectedAction());
  }

  /**
   * A session task-ok auto close feature-t felel
   * @private
   */
  #startAutoCloseRegister() {
    // Ha barmiert mas modon torlik a session task-t akkor ki tudjuk torolni ha van futo task
    this.#actions.pipe(ofActionSuccessful(SessionRemoveTaskAction)).subscribe(({ taskId }) => {
      // Ha van aktiv akkor el kell tavolitani
      if (this.#taskScheduler.isActive(taskId)) {
        this.#taskScheduler.removeTasks(taskId);
      }
      // Ha varakozo sorban van akkor is el kell tavolitani
      if (!isNil(this.#waitingPanelOpen) && this.#waitingPanelOpen.has(taskId)) {
        this.#waitingPanelOpen.delete(taskId);
      }
    });

    /**
     * Ha valtozik egy session message akkor kezeljuk az autoClose-t is
     * mivel ez az esemeny valtodik ki amikor hozzaadasra kerul vagy modositasra,
     * ezert minden esetet vizsgalunk
     */
    this.#actions
      .pipe(ofActionSuccessful(SessionChangeSessionMessageAction))
      .subscribe(({ taskId }) => {
        // Lekerdezzuk a task-hoz tartozo autoClose-t ha van
        const autoClose = this.#store.selectSnapshot(SessionState.taskByTaskId(taskId))?.autoClose;
        // Tudnunk kell hogy nyitva van-e a session task list panel
        const panelIsOpen = this.#store.selectSnapshot(SessionState.panelIsOpen);
        // Megnezzuk van-e mar futo idozito a taskId -hoz rendelve
        if (this.#taskScheduler.isActive(taskId)) {
          // Van elozo futo idozito ami a task id-hoz tartozik
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const storedAutoClose = this.#taskScheduler.getActive(taskId)!.data;
          // Ha van futo de ugyan az a config akkor nem csinalunk semmit
          if (autoClose === storedAutoClose) {
            return;
          }
          // Ha van futo de nem egyezik az auto close a mar futoval, akkor leallitjuk a futo
          if (autoClose !== storedAutoClose) {
            this.#taskScheduler.removeTasks(taskId);
          }
        }

        // Ha van auto close config akkor bejegyezzuk a task-t
        if (!isNil(autoClose)) {
          // Ha `Trigger.Automatic` vagy nyitva a panel akkor rogton el kell inditani a scheduler-t
          if (autoClose.trigger === TaskAutoClose.Trigger.Automatic || panelIsOpen) {
            // start new
            this.#addNewTaskToTaskScheduler(taskId, autoClose);
          } else {
            // Ha `Trigger.BarVisible` van akkor be kell raknunk varakozo sorba amig ki nem nyitjak a session task list panel-t
            if (isNil(this.#waitingPanelOpen)) {
              this.#waitingPanelOpen = new Map();
            }
            this.#waitingPanelOpen?.set(taskId, autoClose);
          }
        }
      });

    // Figyeljuk a session task list panel kinyitasat
    this.#store.select(SessionState.panelIsOpen).subscribe(open => {
      // ha kinyitottak es van varakozo sorban elem akkor bejegyezzuk a scheduler-be
      if (open === true) {
        if (!isNil(this.#waitingPanelOpen)) {
          if (this.#waitingPanelOpen.size > 0) {
            // varakozo taskokat hozza adjuk a schedulerhez
            this.#taskScheduler.addTasks(
              Array.from(this.#waitingPanelOpen.entries()).map(([taskId, autoClose]) =>
                this.#generateTaskSchedulerTask(taskId, autoClose),
              ),
            );
          }
          this.#waitingPanelOpen = undefined;
        }
      }
    });
  }

  /**
   * Ha vegez egy task akkor ezt a callback-et fogja visszahivni
   * @param taskId
   * @private
   */
  #taskCompleteCallback(taskId: TaskId) {
    return () => this.#store.dispatch(new SessionRemoveTaskAction(taskId));
  }

  /**
   * TaskScheduler-be jegyzi be a futtatando task-ot
   * @param taskId
   * @param autoClose
   * @private
   */
  #addNewTaskToTaskScheduler(taskId: TaskId, autoClose: TaskAutoClose): void {
    this.#taskScheduler.addTasks(this.#generateTaskSchedulerTask(taskId, autoClose));
  }

  #generateTaskSchedulerTask(
    taskId: string,
    autoClose: TaskAutoClose,
  ): Omit<SchedulerTaskDefinition<TaskAutoClose>, 'started'> {
    return {
      taskId,
      delay: autoClose.delayMs,
      completeCallback: this.#taskCompleteCallback(taskId),
      data: autoClose,
    };
  }
}
