import { Provider } from '@angular/core';
import { environment } from '@camino-solutions/core/environment';
import { grpc } from '@improbable-eng/grpc-web';
import { ImprobableEngGrpcWebClientSettings } from '@ngx-grpc/improbable-eng-grpc-web-client';
import { GRPC_SESSION_SERVICE_CLIENT_SETTINGS } from '@camino-solutions/share/proto/common/session';
import { SessionService } from './session.service';

const xhr = grpc.CrossBrowserHttpTransport({
  /*withCredentials: true */
});

export const SessionServiceProvider: Provider[] = [
  {
    provide: GRPC_SESSION_SERVICE_CLIENT_SETTINGS,
    useValue: {
      host: environment.urls.common.session,
      transport: {
        unary: xhr,
        serverStream: xhr,
        // We not use clientStream and bidiStream
        clientStream: xhr,
        bidiStream: xhr,
      },
    } satisfies ImprobableEngGrpcWebClientSettings,
  },
  SessionService,
];
